.footer-div{
    background-color: white;
    justify-content: space-around;
}
.footer-copyryght{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    font-weight: 500;
}
.footer-link{
    text-decoration: none;
    color: #bf0f11;
    list-style: none;
    margin: 0 5px;
}
.footer-title{
    font-size: 20px;
    font-weight: 600;
}
.FontAwesomeIcon{
    margin: 0 5px;
}
.footer-reseau{
    padding: 5% 0 5% 0;
}
