.nav-img{
    width: 100px;
}

.navigation {
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:beige;
    clip-path:circle(0px at 100% 0);
    transition:all 500ms ease-in-out;
    z-index: -1;
}
.navigation.active {
    z-index:40;
    clip-path:circle(75%);
}

.navigation .links {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    text-align:center;
}
.navigation .links a {
    position:relative;
    display:inline-block;
    margin:20px 0;
    font-size:20px;
    font-weight:600;
    text-decoration:none;
    color:#111;
    text-transform:uppercase;
    letter-spacing:5px;
}
.navigation .links a:after {
    content:"";
    position:absolute;
    left:0;
    bottom:-5px;
    height:2px;
    width:0;
    background: #bf0f11;
    transition:all 300ms ease-in-out;
}
.navigation .links a:hover:after {
    width:100%;
}
.navbar-reseau{
    font-weight: 450!important;
}

