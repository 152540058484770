.single-img{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 300px;
    height: 250px;
}
@media (min-width: 400px) {
    .single-img{
        width: 400px;
        height: 300px;
    }
    .projet-format{
        width: 400px;
        height: 250px;
    }
}
.sizeImgProjets img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.projet-format{
    width: 350px;
    height: 220px;
    margin: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.texte{
    position: absolute;
    bottom: 20px;
    left: 20px;
    text-decoration: none;
    color: white;
    opacity: 0;
    font-weight: 800;
    transition: 0.3s;
    transition-timing-function: ease-in-out;
}
.texte2{
    position: absolute;
    bottom: 20px;
    left: 20px;
    text-decoration: none;
    color: white;
    opacity: 0;
    font-weight: 800;
    transition: 0.3s;
    transition-timing-function: ease-in-out;
}
.texte3{
    position: absolute;
    bottom: 0px;
    left: 20px;
    text-decoration: none;
    color: white;
    opacity: 0;
    font-weight: 800;
    transition: 0.3s;
    transition-timing-function: ease-in-out;
}
.projets-shadow{
    position: absolute;
    left: 0;
    top:0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.3s;
    transition-timing-function: ease-in-out;
    background-color: rgba(0,0,0,0.4);
}
a .projets-shadow{
    opacity: 1;
}
a .texte{
    opacity: 1;
}
a .texte2{
    opacity: 1;
}
a .texte3{
    opacity: 1;
}


@media (min-width: 720px){
    a .projets-shadow{
        opacity: 0;
    }
    a .texte{
        opacity: 0;
    }
    a .texte2{
        opacity: 0;
    }
    a:hover .projets-shadow{
        opacity: 1;
    }
    a:hover .texte{
        opacity: 1;
    }
    a:hover .texte2{
        opacity: 1;
    }
}
.mosaic{
    width: 50%;
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

@media screen and (min-width: 900px) and (max-width: 1249px){
    .mosaic{
        width: 33.33333%;
    }
}
