.logo{
    width: 100px;
    height: auto;
}
.login-centre {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-height: 75vh;
}
.login-display{
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    width: 200px;
    background-color: darkgrey;
}
