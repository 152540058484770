
/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Calibri Light", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer components {
  .btn-blue {
    @apply py-2 px-4 bg-blue-500 text-white font-semibold shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75;
  }
  .btn-classic {
    @apply px-6 py-2 font-bold border-2 border-dhpaysage text-dhpaysage hover:bg-dhpaysage hover:text-white hover:border-transparent focus:outline-none;
  }
  .btn-svg {
    @apply px-6 py-2 font-bold border-2 border-dhpaysage text-dhpaysage hover:bg-dhpaysage hover:text-white hover:border-transparent flex items-center;
  }
  .btn-save {
    @apply col-start-2 px-4 py-1 text-green-600 font-semibold border border-green-200 hover:text-white hover:bg-green-600 hover:border-transparent focus:outline-none;
  }
  .btn-delete {
    @apply px-4 py-1 text-red-600 font-semibold border border-red-200 hover:text-white hover:bg-red-600 hover:border-transparent focus:outline-none;
  }
  .alert-save {
    @apply z-50 fixed top-0 left-0 w-screen border border-t-0 border-green-400 bg-green-100 px-4 py-3 text-green-700;
  }
  .alert-delete {
    @apply bg-red-500 text-white font-bold px-4 py-2;
  }
  .category-box{
    @apply  focus:outline-none font-light text-gray-700 hover:text-gray-900
  }
  .inputBox {
    @apply border border-gray-300 rounded box-border bg-gray-100
  }
}
