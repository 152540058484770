.extendLinkLeft:hover .imgExtendLeft{
    width: 200px;
}
.imgExtendLeft{
    transition: width, 0.7s;
    width: 0;
    height: 100px;
}
.extendLinkRight:hover .imgExtendRight{
    width: 200px;
    transform: translateX(-190px) translateY(-103px);
}
.imgExtendRight{
    transition: all, 0.7s;
    width: 0;
    height: 100px;
}

