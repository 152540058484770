.admin-newproject{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 300px;
    height: 250px;
}
.space-white {
    white-space: pre-line;
}
@media (min-width: 400px) {
    .admin-newproject{
        width: 400px;
        height: 300px;
    }
}
.admin-agence-toogler{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 150px;
    height: 125px;
}
@media (min-width: 400px) {
    .admin-agence-toogler{
        width: 200px;
        height: 150px;
    }
}
.admin-projet-reverse{
    display: flex;
    flex-direction: column-reverse;
}
.news-admin-reverse{
    display: flex;
    flex-direction: column-reverse;
}

.admin-home{
    padding: 10vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
}
@media (max-width: 400px) {
    .admin-home{
        padding: 10vh;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
    }
}
.hidden-check1 {
    display: none;
}
.admin-update-img{
    width: 150px;
    height: auto;
}
.admin-select{
    width:150px;
    height: auto;
}
.admin-select-menu{
    width: 100%;
}